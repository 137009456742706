import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Carousel from 'react-multi-carousel';
import { H1, P, Image, ButtonLink, Link } from '../components';
import { TitleImage } from '../components/images/beers';
import { useBeers } from '../hooks';
import { getBorderColorClassForBeer, getTextColorClassForBeer } from '../utils';

export default function Beers() {
  const beers = useBeers();
  return (
    <Layout title="Our Beer Selection">
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60">
        <H1 className="mb-32">Our Beer Selection</H1>
        <div className="w-full flex flex-col lg:flex-row justify-center items-center lg:items-start lg:justify-evenly lg:m-auto">
          {beers.map((beer) => (
            <div key={beer.slug} className="flex flex-col justify-center items-center text-center gap-10 lg:gap-16">
              <Link to={`/beers/${beer.slug}`} className="block">
                <Image src={beer.bottleShadowImg} alt={`A ${beer.name} beer bottle`} className="w-28 lg:w-40 xl:w-48" />
              </Link>
              <Link to={`/beers/${beer.slug}`} className="block">
                <TitleImage beer={beer} />
              </Link>
              <p className={`text-xl w-48 lg:w-48 xl:w-48 h-32 ${getTextColorClassForBeer(beer)}`}>
                {beer.description}
              </p>
              <ButtonLink
                overrideBtnClassName={`border ${getBorderColorClassForBeer(
                  beer,
                  'override:',
                )} ${getTextColorClassForBeer(beer, 'override:')}`}
                to={`/beers/${beer.slug}`}
                main
              >
                Discover
              </ButtonLink>
            </div>
          ))}
        </div>
        <H1 className="lg:mt-32 lg:mb-20 override:mt-32 override:mb-10">The brewery</H1>
        <Carousel responsive={{ uniform: { breakpoint: { min: 0, max: 4000 }, items: 1 } }} showDots={true} ssr>
          <section className="w-full flex flex-col lg:flex-row justify-center items-center gap-10">
            <Image
              src="beers/brasserie_backdoor.jpeg"
              alt="Colorful painting on backside of brasserie building"
              className="w-full lg:w-1/3 xl:w-1/2 max-w-5xl"
            />
            <div>
              <H1>Culture & Community</H1>
              <P>Through culture and community, we explore the art of brewing and the lifestyle associated with it. </P>
              <P>
                We respect our heritage and share different ways of living. We connect cultures through artful science
                of beer and gastronomic pairings.
              </P>
              <P>Be a part of the living heritage through consumption of beer!</P>
            </div>
          </section>
          <section className="w-full flex flex-col lg:flex-row justify-center items-center gap-10">
            <Image
              src="beers/bertrand.jpeg"
              alt="Brewer Bertrand inspects beer in brewery"
              className="w-full lg:w-1/3 xl:w-1/2 max-w-5xl"
            />
            <div>
              <H1>Science</H1>
              <P>
                Through science, we journey into the depths of human existence. We acknowledge and use the techniques
                learned over the years.
              </P>
              <P>
                We bring you the unexplored corners of the ancient recipe of water, carb-laden grain, flavourful hops
                and yeast that magically spins sugar into your favourite beer.
              </P>
            </div>
          </section>
          <section className="w-full flex flex-col lg:flex-row justify-center items-center gap-10">
            <Image src="beers/house.jpeg" alt="Old factory building" className="w-full lg:w-1/3 xl:w-1/2 max-w-5xl" />
            <div>
              <H1>Tradition</H1>
              <P>History defines us.</P>
              <P>We work with generational brewers who exhibit eternal pleasure of brewing with passion.</P>
              <P>Our respect for the legacy of artisanal Belgian beers is what defines us.</P>
            </div>
          </section>
        </Carousel>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
