import React from 'react';
import { Beer } from '../../../types';
import Image from '../image';

interface TitleImageProps {
  beer: Beer;
}

const TitleImage: React.FC<TitleImageProps> = ({ beer }) => {
  return <Image src={beer.titleImg} alt={beer.name} className="w-40 lg:w-40 xl:w-44 lg:h-26" />;
};

export default TitleImage;
